import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import isEqual from 'lodash-es/isEqual';
import { Subscription } from 'rxjs';

import { SearchInputComponent } from '../form-inputs/search/search-input.component';
import { TreeviewConfig } from '../ngx-treeview/treeview-config';
import { TreeviewHeaderTemplateContext } from '../ngx-treeview/treeview-header-template-context';
import { TreeviewI18n } from '../ngx-treeview/treeview-i18n';
import { TreeviewItem } from '../ngx-treeview/treeview-item';
import { TreeviewItemTemplateContext } from '../ngx-treeview/treeview-item-template-context';
import { TreeviewModule } from '../ngx-treeview/treeview.module';
import { SelectI18nService } from './select.i18n.service';

const noop = () => {};

export const SINGLE_SELECT_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
};

@Component({
  selector: 'dl-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './select.component.html',
  providers: [SINGLE_SELECT_INPUT_CONTROL_VALUE_ACCESSOR, { provide: TreeviewI18n, useClass: SelectI18nService }],
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    SearchInputComponent,
    TreeviewModule,
    TranslateModule,
    MatIconModule,
  ],
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public config: TreeviewConfig = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: true,
    maxHeight: 250,
  });

  @Input()
  public headerTemplate: TemplateRef<TreeviewHeaderTemplateContext>;
  @Input()
  public itemTemplate: TemplateRef<TreeviewItemTemplateContext>;

  @Output()
  public conflictFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _items: TreeviewItem[];
  private selected: any;

  public disabled = false;
  public filterText = '';

  private filterSubscription: Subscription;
  public employeeFilterCheckbox = new UntypedFormControl(false);
  // internal functions to call by ControlValueAccessor
  private onTouched: () => void = noop;
  private onModelChange: (_: any) => void = noop;

  private dropdownTreeviewSelectI18n: SelectI18nService;

  @Input()
  public set items(items: TreeviewItem[]) {
    //modify items so selected values are checked ( and all others will be unchecked )
    this.updateItems(items, this.selected);
  }

  public get items() {
    return this._items;
  }

  public constructor(
    public i18n: TreeviewI18n,
    private _cd: ChangeDetectorRef,
  ) {
    this.dropdownTreeviewSelectI18n = i18n as SelectI18nService;
  }

  public ngOnInit() {
    this.filterSubscription = this.employeeFilterCheckbox.valueChanges.subscribe((value) => {
      this.conflictFilter.emit(value);
    });
  }

  public ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  public select(item: TreeviewItem) {
    if (item.children === undefined) {
      this.selectItem(item);
    }
  }

  private selectItem(item: TreeviewItem) {
    if (item.disabled) {
      return;
    }

    if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
      this.dropdownTreeviewSelectI18n.selectedItem = item;
      if (this.selected !== item.value) {
        this.selected = item.value;
        this.onModelChange(item.value);
        this.updateItems(this._items, this.selected);
      }
    }
  }

  /**
   * set the checked property on all Treeview items
   * @param {TreeviewItem[]} items
   * @param {string[]} selected
   */
  private updateItems(items: TreeviewItem[], selected: any) {
    // recursively walk through all treeview items and set the checked value
    this._items = items.map((item) => this.mapItem(item, selected));
    this.selected = selected;
  }

  private updateTreeLeaf(item: TreeviewItem, selected: any) {
    const shouldBeChecked = selected === item.value;
    if (shouldBeChecked) {
      this.dropdownTreeviewSelectI18n.selectedItem = item;
    }

    // do not mutate if there are no changes
    if (item.checked === shouldBeChecked) {
      return item;
    }

    const modifiedItem = {
      ...item,
      checked: shouldBeChecked,
    };

    return new TreeviewItem(modifiedItem);
  }

  private updateTreeParent(item: TreeviewItem, selected: any) {
    const children = item.children.map((child) => this.mapItem(child, selected));

    // do not mutate parent if there are no changes
    if (isEqual(children, item.children)) {
      return item;
    }

    const modifiedParent = {
      ...item,
      children,
    };

    return new TreeviewItem(modifiedParent, true);
  }

  /**
   * Recursively map items and set checked property without mutating the original items
   * @param {TreeviewItem} item
   * @param {string[]} selected
   * @returns {TreeviewItem}
   */
  private mapItem(item: TreeviewItem, selected: any) {
    if (item.children && item.children.length > 0) {
      return this.updateTreeParent(item, selected);
    }

    //update TreeLeave item
    return this.updateTreeLeaf(item, selected);
  }

  /********************************
   * ControlValue methods
   ********************************/

  /**
   * This is called from a form input to set the internal value
   * @param {string[]} selected
   */
  public writeValue(selected: any): void {
    if (selected === this.selected) {
      return;
    }

    if (!selected) {
      this.dropdownTreeviewSelectI18n.resetSelectedItem();
    }

    this.updateItems(this.items, selected);
    this._cd.markForCheck();
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
