import { Injectable } from '@angular/core';
import { MultiSelectI18nService } from '../multiselect/multiSelect.i18n.service';
import { TreeviewItem, TreeviewSelection } from '../ngx-treeview/treeview-item';

@Injectable()
export class SelectI18nService extends MultiSelectI18nService {
  private internalSelectedItem: TreeviewItem;

  set selectedItem(value: TreeviewItem) {
    if (value && value.children === undefined) {
      this.internalSelectedItem = value;
    }
  }

  get selectedItem(): TreeviewItem {
    return this.internalSelectedItem;
  }

  getText(selection: TreeviewSelection): string {
    return this.internalSelectedItem ? this.internalSelectedItem.text : '';
  }

  resetSelectedItem() {
    this.internalSelectedItem = null;
  }
}
