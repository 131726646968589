import { map } from 'rxjs/operators';

import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { EmployeeModel } from '../../reducers/orm/employee/employee.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { getEmployeeEntities } from '../../reducers/orm/employee/employee.service';
import { UntypedFormGroup } from '@angular/forms';
import { TeamModel } from '../../reducers/orm/team/team.model';
import { getTeamEntities } from '../../reducers/orm/team/team.service';
import { ControlMessages } from '../../forms/control-messages.component';
import { TreeviewItem } from '../ngx-treeview/treeview-item';
import get from 'lodash-es/get';
import mapValues from 'lodash-es/mapValues';
import { trackByIdFn } from '../trackby';
import { contractInfoPerEmployeePerDay, getContracts } from '../../reducers/orm/contract/contract.service';
import { getEmployabilityEntities } from '@reducers/orm/employability/employability.selector';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class AbstractScheduleEmployeeComponent implements OnInit, OnDestroy {
  // Has to be ViewChildren because they are hidden at first.
  @ViewChildren(ControlMessages)
  public controlMessages: QueryList<ControlMessages>;

  @Input()
  public set items(items: TreeviewItem[]) {
    this._items = items;
    this.originalItems = items;
  }

  public get items(): TreeviewItem[] {
    return this._items;
  }

  private _items: TreeviewItem[];
  private originalItems: TreeviewItem[];

  @Input()
  public name: string;
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public schedule_id: string;
  @Input()
  public date: string;
  @Input()
  public starttime: string;
  @Input()
  public endtime: string;
  @Input()
  public moveToOpenShifts: boolean = false;
  @Input()
  public shift_id: string;

  public isOpen = false;

  public employees: { [id: string]: EmployeeModel } = {};

  public teams: { [id: string]: TeamModel } = {};

  public trackById = trackByIdFn;

  private dataSubs = new Subscription();

  public constructor(private store: Store<AppState>, element: ElementRef, protected translate: TranslateService) {}

  public ngOnInit() {
    this.dataSubs.add(
      observableCombineLatest([
        this.store.select(getEmployeeEntities),
        this.store
          .select(getContracts)
          .pipe(map((contracts) => contractInfoPerEmployeePerDay(this.date, this.date, contracts))),
        this.store.select(getEmployabilityEntities),
      ])
        .pipe(
          map(([employees, contracts, employability]) =>
            mapValues(employees, (employee: EmployeeModel) => {
              const wage = get(contracts, `${employee.id}.${this.date}.wage`, '0.00');
              const employeeEmployability = employability[employee.id];
              const onlyLackSkills =
                !employeeEmployability?.employable &&
                !!employeeEmployability?.details &&
                !employeeEmployability?.details?.schedule?.length &&
                !employeeEmployability?.details?.absence?.length &&
                !employeeEmployability?.details?.availability;
              return {
                ...employee,
                wage,
                employability: employeeEmployability,
                onlyLackSkills,
              };
            })
          )
        )
        .subscribe((employees) => (this.employees = employees))
    );

    this.dataSubs.add(this.store.select(getTeamEntities).subscribe((teams) => (this.teams = teams)));
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public get hasDanger() {
    if (!this.controlMessages) {
      return false;
    }

    const controlMessage = this.controlMessages.first;

    return controlMessage ? controlMessage.showError : false;
  }

  public open(event: Event) {
    this.isOpen = true;

    /*
      Prevents `onClickOutside` from being called through document:click.
      Otherwise it would close again straight away.
    */
    event.stopPropagation();
  }

  public close() {
    this.isOpen = false;
  }

  public toggleConflictFilter(toggle) {
    if (toggle) {
      const items = [...this.originalItems];

      const filteredItems = items
        .map((department) => {
          const children = department.children.filter((employee) => {
            const employeeWithConflicts = this.employees[employee.value];
            if (!employeeWithConflicts.employability) {
              return true;
            }
            return employeeWithConflicts.employability?.employable;
          });

          return {
            ...department,
            children,
          } as TreeviewItem;
        })
        .filter((department) => department.children && department.children.length > 0);

      const empty = filteredItems.every((department) => department.children.length === 0);
      this._items = empty ? [] : filteredItems;
    } else {
      this._items = [...this.originalItems];
    }
  }
}
