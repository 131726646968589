import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PermissionDirective } from '@app/+authenticated/shared/permission.directive';
import { ControlMessages } from '@app/forms/control-messages.component';
import { MoneyPipe } from '@app/pipes/money.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { MultiselectComponent } from '../multiselect/multiselect.component';
import { AbstractScheduleEmployeeComponent } from './abstract-schedule-employee.component';
import { EmployeeSelectItemComponent } from './employee-select-item.component';

@Component({
  selector: 'dl-schedule-employees',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule-employees.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MultiselectComponent,
    ReactiveFormsModule,
    EmployeeSelectItemComponent,
    MoneyPipe,
    TranslateModule,
    ControlMessages,
    PermissionDirective,
  ],
})
export class ScheduleEmployeesComponent extends AbstractScheduleEmployeeComponent {
  public itemClicked(onCheckedChange, item) {
    item.checked = !item.checked;
    onCheckedChange();
  }
}
