import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EmployabilityModel } from '@reducers/orm/employability/employability.model';

export interface EmployabilityState extends EntityState<EmployabilityModel> {
  isLoading?: boolean;
  error?: any;
}

export const employabilityEntityAdapter: EntityAdapter<EmployabilityModel> = createEntityAdapter<EmployabilityModel>({
  selectId: (model) => model.employeeId,
});

export const initialState: EmployabilityState = employabilityEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
