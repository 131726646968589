import { AppState } from '@app/reducers';
import { employabilityEntityAdapter, EmployabilityState } from '@reducers/orm/employability/employability.state';

export const getEmployabilityState = (appState: AppState): EmployabilityState => appState.orm.employability;

export const {
  selectAll,
  selectEntities: getEmployabilityEntities,
  selectIds,
  selectTotal,
} = employabilityEntityAdapter.getSelectors(getEmployabilityState);
