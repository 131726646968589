<ng-template
  #itemInlineTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-check">
    <i
      *ngIf="item.children"
      (click)="onCollapseExpand()"
      aria-hidden="true"
      class="fa"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"
    ></i>
    <label class="checkbox">
      <input
        type="checkbox"
        class="checkbox-input"
        (click)="select(item)"
        [checked]="item.checked"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      />
      <span class="checkbox-indicator"></span>
      <span class="checkbox-description">{{ item.text }}</span>
    </label>
  </div>
</ng-template>

<ng-template
  #defaultHeaderTemplate
  let-item="item"
  let-config="config"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
  let-onFilterTextChange="onFilterTextChange"
>
  <div *ngIf="config.hasFilter" class="w-14rem">
    <search-input [(ngModel)]="filterText" (ngModelChange)="onFilterTextChange($event)"></search-input>
  </div>
  <div *ngIf="config.hasConflictFilterCheckBox" class="row">
    <div *ngIf="config.hasConflictFilterCheckBox" class="col-lg-12">
      <label class="checkbox">
        <input type="checkbox" class="checkbox-input" [formControl]="employeeFilterCheckbox" />
        <span class="checkbox-indicator"></span>
        <span class="checkbox-description">{{ 'Show compatible employees' | translate }}</span>
      </label>
    </div>
  </div>
</ng-template>

<ngx-treeview
  [items]="items"
  [config]="config"
  [itemTemplate]="itemTemplate ? itemTemplate : itemInlineTemplate"
  [headerTemplate]="headerTemplate ? headerTemplate : defaultHeaderTemplate"
>
</ngx-treeview>
