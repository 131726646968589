import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PermissionDirective } from '@app/+authenticated/shared/permission.directive';
import { ControlMessages } from '@app/forms/control-messages.component';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeSelectItemComponent } from '@shared/schedule-employee/employee-select-item.component';
import { SubscriptionPlanDirective } from '@shared/subscription-plan/subscription-plan.directive';

import { PlanType } from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { MoneyPipe } from '../../pipes/money.pipe';
import { TreeviewItem } from '../ngx-treeview/treeview-item';
import { SelectComponent } from '../select/select.component';
import { AbstractScheduleEmployeeComponent } from './abstract-schedule-employee.component';

@Component({
  selector: 'dl-schedule-employee',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule-employee.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EmployeeSelectItemComponent,
    ControlMessages,
    SubscriptionPlanDirective,
    ReactiveFormsModule,
    SelectComponent,
    PermissionDirective,
    MoneyPipe,
  ],
})
export class ScheduleEmployeeComponent extends AbstractScheduleEmployeeComponent {
  @Input()
  public alwaysOpen = false;
  @Input()
  public moveToOpenShifts = false;
  @Input()
  public date: string;
  @Input()
  public assignTmp: TemplateRef<any>;

  // Has to be ViewChildren because they are hidden at first.
  @ViewChildren(SelectComponent)
  public selectComponents: QueryList<SelectComponent>;

  public planType = PlanType;

  public select(item: TreeviewItem) {
    const selectComponent = this.selectComponents.first;

    if (selectComponent) {
      selectComponent.select(item);
      this.close();
    }
  }

  public selectOpenShift() {
    const openShiftItem = new TreeviewItem({
      text: this.translate.instant('Open shift'),
      value: 'open_shift',
    });

    const selectComponent = this.selectComponents.first;

    if (selectComponent) {
      selectComponent.select(openShiftItem);
      this.close();
    }
  }
}
